import { strategiesConfig, StrategiesConfig } from "./strategies.config";

export interface AppConfig {
    strategies: StrategiesConfig;
    morphoApiBaseUrl: string;
    morphoRewardsApiBaseUrl: string;
}

export const appConfig: AppConfig = {
    strategies: strategiesConfig,
    morphoApiBaseUrl: 'https://blue-api.morpho.org',
    morphoRewardsApiBaseUrl: 'https://rewards.morpho.org'
};
