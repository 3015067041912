import { appConfig } from "../../config";
import { RestClient } from "../client";
import { MorphoResponse, MorphoRewardsResponse } from "./types";

interface IMorphoApiService {
    getApy: (address: string) => Promise<MorphoResponse>;
}

export class MorphoApiService implements IMorphoApiService {
    constructor(
        private restClient = new RestClient(appConfig.morphoApiBaseUrl),
        private rewardsRestClient = new RestClient(appConfig.morphoRewardsApiBaseUrl)
    ) { }

    async getApy(address: string): Promise<MorphoResponse> {
        const query = `
            query {
                vaults(
                    where: { address_in: ["${address}"] }
                ) {
                    items {
                        symbol
                        name
                        state {
                            apy
                            netApy
                            totalAssets
                            totalAssetsUsd
                        }
                    }
                }
            }
        `;

        const response = await this.restClient.post<{ query: string }, MorphoResponse>('/graphql', {
            query: query
        });
        return response;
    }

    async getRewards(address: string): Promise<MorphoRewardsResponse> {
        const response = await this.rewardsRestClient.get<MorphoRewardsResponse>(`/v1/users/${address}/rewards`);
        return response;
    }
}