import React from "react";
import { MorphoResponse, MorphoApiService, MorphoRewardsResponse } from "../../rest/morpho";
import { Strategy } from "../types";
import { AppConfigContext } from "../../context";
import { useAccount } from "wagmi";

export const useRewardsData = (strategy: Strategy) => {
    const morphoApiService = new MorphoApiService();
    const [rewardsData, setRewardsData] = React.useState<MorphoRewardsResponse>();

    const fetchRewardsData = async () => {
        try {
            setRewardsData(await morphoApiService.getRewards("0xFbA64167e4f091Ca625FA79aa6f83665856f8Bf2"));
        } catch (e) {
            console.error(`Failed to fetch allocation data: ${e}`);
        }
    };

    React.useEffect(() => {
        fetchRewardsData();
    }, [strategy]);

    return { rewardsData };
};